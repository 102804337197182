import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Button, Box, Collapse } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logoImage from '../assets/ideas_logo.png';

const StyledAppBar = styled(AppBar)(({ isScrolled, showNavbar }) => ({
  position: "fixed",
  top: 0,
  width: "100%",
  background: isScrolled ? "rgba(0, 0, 0, 1)" : "transparent",
  color: "#fff",
  padding: "0 1rem",
  boxShadow: isScrolled ? "0px 4px 12px rgba(0, 0, 0, 0.3)" : "none",
  transition: "background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease",
  transform:
    showNavbar || window.scrollY === 0 ? "translateY(0)" : "translateY(-100%)",
  zIndex: 10,
}));

const LogoImage = styled('img')({
  width: 50,
  height: 50,
  marginRight: 0,
  cursor: 'pointer',
});

function Navbar({ onDonateClick }) {
  const [active, setActive] = useState("Home");
  const [isScrolled, setIsScrolled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const sectionOffsets = [
    { label: 'Home', id: 'Home' },
    { label: 'About', id: 'About' },
    { label: 'Gallery', id: 'Gallery' },
    
    { label: 'Contact', id: 'Contact' },
    { label: 'Donate', id: 'Donate' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 500);
      setShowNavbar(window.scrollY === 0 || window.scrollY > 500);

      // Determine which section is in view
      for (let i = sectionOffsets.length - 1; i >= 0; i--) {
        const section = document.getElementById(sectionOffsets[i].id);
        if (section) {
          const sectionTop = section.getBoundingClientRect().top + window.scrollY;
          if (window.scrollY >= sectionTop - window.innerHeight / 2) {
            setActive(sectionOffsets[i].label);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLogoClick = () => {
    setActive("Home");
    setIsExpanded(false); // Collapse menu
    if (location.pathname !== "/") {
      navigate("/");
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    
  };

  const handleSetActive = (label) => {
    if (label === "Donate") {
      onDonateClick(); // Trigger the popup when Donate is clicked
      return;
    }
    
    if (label === "Join Us") {
      window.open("https://docs.google.com/forms/d/e/1FAIpQLSfdv4bPiscm-fpzWBPRCEzcgOc2tO8bvRZm7rvifrY7YBg05w/viewform", "_blank");
      return;
    }

    if (label === "Home") {
      handleLogoClick();
      return;
    }

    const scrollToSection = () => {
      const section = document.getElementById(label);
      if (section) {
        const yOffset = -64; // Offset for navbar height if needed
        const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    };

    if (location.pathname !== "/" && label !== "Gallery") {
      navigate("/");
      setTimeout(scrollToSection, 100); // Delay to ensure homepage loads before scrolling
    } else {
      setActive(label);
      scrollToSection();
    }

    setIsExpanded(false); // Collapse menu after selecting an item
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <StyledAppBar
      isScrolled={isScrolled}
      showNavbar={showNavbar}
      position="absolute"
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{ flexGrow: { xs: 1, md: 0 } }}
        >
          <IconButton edge="start" color="inherit" aria-label="logo" onClick={handleLogoClick}>
            <LogoImage src={logoImage} alt="Giving Logo" />
          </IconButton>
        </Box>

        {/* Join Us Button on Small Screens */}
        <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
          <Button
            color="inherit"
            sx={{
              background: 'linear-gradient(45deg, #E05B23, #FF8B04)',
              color: '#fff',
              fontWeight: 'bold',
              borderRadius: '20px',
              padding: '6px 12px',
              boxShadow: '0px 4px 12px rgba(255, 126, 95, 0.5)',
              marginRight: 2,
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 6px 15px rgba(255, 126, 95, 0.7)',
              },
            }}
            onClick={() => handleSetActive("Join Us")}
          >
            Join Us
          </Button>
          <IconButton color="inherit" onClick={toggleExpand}>
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Navbar Links on Medium and Larger Screens */}
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            gap: 2,
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            width: { md: 'auto' },
          }}
        >
          {["Home", "About", "Gallery",  "Contact", "Donate",].map((label) => (
            <Button
              key={label}
              color="inherit"
              sx={{
                borderBottom: active === label ? '2px solid #fff' : 'none',
                borderRadius: 0,
                fontWeight: active === label ? 'bold' : 'normal',
                '&:hover': {
                  backgroundColor: 'transparent',
                  borderBottom: '2px solid #fff',
                },
              }}
              onClick={() => handleSetActive(label)}
            >
              {label}
            </Button>
          ))}
          <Button
            color="inherit"
            sx={{
              background: 'linear-gradient(45deg, #E05B23, #FF8B04)',
              color: '#fff',
              fontWeight: 'bold',
              borderRadius: '20px',
              padding: '6px 18px',
              boxShadow: '0px 4px 12px rgba(255, 126, 95, 0.5)',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 6px 15px rgba(255, 126, 95, 0.7)',
              },
            }}
            onClick={() => handleSetActive("Join Us")}
          >
            Join Us
          </Button>
        </Box>
      </Toolbar>

      {/* Collapsible Menu on Small Screens */}
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            padding: 2,
          }}
        >
          {["Home", "About", "Gallery", "Donate", "Contact"].map((label) => (
            <Button
              key={label}
              color="inherit"
              sx={{
                borderBottom: active === label ? "2px solid #fff" : "none",
                borderRadius: 0,
                fontWeight: active === label ? "bold" : "normal",
                width: "100%",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #fff",
                },
              }}
              onClick={() => handleSetActive(label)}
            >
              {label}
            </Button>
          ))}
        </Box>
      </Collapse>
    </StyledAppBar>
  );
}

export default Navbar;
