// src/theme.js

const colors = {
  primary: "#ee5c24", // Main color, e.g., for buttons or highlights
  secondary: "#90EE90", // Secondary color for contrast
  backgroundOverlay: "rgba(0, 128, 0, 0.8)", // Overlay green background color
  contentBackground: "rgba(0, 128, 0, 0.6)", // Slightly darker overlay for content
  white: "#FFFFFF",
  darkText: "#333333",
  lightText: "rgba(255, 255, 255, 0.7)",
  progressBar: "#FFD700", // Gold color for progress bars
  progressBarBackground: "rgba(255, 255, 255, 0.3)",
  transparent: "rgba(0, 0, 0, 0)", // Fully transparent
  darkOrange: "#E05B23",
  lightOrange: "#FF8B04",
  darkYellow: "#E0B808",
  darkBlue: "#092156",
  darkGray:"#161616"
};

export default colors;
