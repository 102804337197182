import { BASE_URL } from "../constant";
import API from "../interceptors";
export const fetchAllEvents = async () => {
  console.log("fetching events123");
  const events = await API.get(`http://65.2.122.58:3006/event/fetchAllEvent`);
  return events?.data ?? [];
};

export const createEvent = async (newEvent) => {
  const { data } = API.post(
    `http://65.2.122.58:3006/event/createEvent`,
    newEvent,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

export const updateEvent = async ({ id, formData }) => {
  console.log("id", id);
  const { data } = API.put(
    `http://65.2.122.58:3006/event/editEvent/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

export const deleteEvent = async (id) => {
  console.log("event to be deleted", id);
  const { data } = await API.delete(
    `http://65.2.122.58:3006/event/deleteEvent/${id}`
  );
  return data;
};

export const fetchEventById = async (eventId) => {
  const response = await API.get(
    `http://65.2.122.58:3006/event/fetchEventById/${eventId}`
  );
  return response.data; // Adjust based on your API's response structure
};
