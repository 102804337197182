import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite'; // Placeholder for Health icon
import SchoolIcon from '@mui/icons-material/School'; // Placeholder for Education icon
import PeopleIcon from '@mui/icons-material/People'; // Placeholder for Protect icon
import colors from '../styles/Colors';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
const data = [
  {
    title: "Inspire",
    description: "Encouraging and motivating others to do good in the society.",
    icon: <FavoriteIcon  sx={{ fontSize: 60, color: 'white' }} />
  },
  {
    title: "Nurture",
    description: "Promoting the right well being of every child, in every way possible and be a blessing to them.",
    icon: <VolunteerActivismIcon sx={{ fontSize: 60, color: 'white' }} />
  },
  {
    title: "Empower",
    description: "Turning talents into skills. Finding the impeccable talents of people we work with and make most out of it.",
    icon: <EmojiObjectsIcon sx={{ fontSize: 60, color: 'white' }} />
  }
];

const MissionComponent = () => {
  return (
    <Box sx={{ textAlign: 'center', padding: '2rem' }}>
      {/* Heading Section */}
      <Typography variant="h3"
      //make it bold
        sx={{ fontWeight: 'bold' }}

      gutterBottom>
        Our Mission
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Be the Blessing for Everyone
      </Typography>

      {/* Content Section */}
      <Grid container spacing={4} justifyContent="center">
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper elevation={0} sx={{ padding: '2rem', textAlign: 'center', borderRadius: '50%' }}>
              <Box
                sx={{
                  width: 80,
                  height: 80,
                  backgroundColor:colors.lightOrange,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 auto 1rem auto'
                }}
              >
                {item.icon}
              </Box>
              <Typography variant="h6" gutterBottom>
                {item.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MissionComponent;
