import React from 'react';
import { Box, Typography, Card, CardContent, Divider, Grid } from '@mui/material';
import { styled } from '@mui/system';

// Importing custom logos
import HBLLogo from '../assets/hbl_logo.png';
import EasyPaisaLogo from '../assets/ep_logo.png';
import SadaPayLogo from '../assets/sp_logo.png';
import NayaPayLogo from '../assets/np_logo.jpeg';

const platforms = [
  { 
    name: 'HBL', 
    accountTitle: 'Talha Azhar', 
    accountNumber: '23287900299303',
    logo: HBLLogo
  },
  { 
    name: 'EasyPaisa', 
    accountTitle: 'Talha Azhar', 
    accountNumber: '0332-8725511',
    logo: EasyPaisaLogo
  },
  { 
    name: 'SadaPay', 
    accountTitle: 'Talha Azhar', 
    accountNumber: '0332-8725511',
    logo: SadaPayLogo
  },
  { 
    name: 'NayaPay', 
    accountTitle: 'Talha Azhar', 
    accountNumber: '0332-8725511',
    logo: NayaPayLogo
  },
];

// Custom styles for each card
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  padding: theme.spacing(2),
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
  },
}));

function PaymentDetails() {
  return (
    <Box
      sx={{
        padding: 4,
        textAlign: 'center',
        backgroundColor: '#f5f5f5',
        minHeight: '20vh',
        width: '100%',
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3, color: '#333' }}>
        Support Us Through Your Preferred Payment Method
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, color: '#666' }}>
        Choose from any of the payment options below to make a contribution.
      </Typography>

      {/* Grid layout with two rows and two columns */}
      <Grid container spacing={3} justifyContent="center">
        {platforms.map((platform, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}> {/* Ensure the items are 50% width on medium screens */}
            <StyledCard>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 2,
                  }}
                >
                  {/* Displaying the custom logo */}
                  <img src={platform.logo} alt={`${platform.name} logo`} style={{ width: 40, height: 40 }} />
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                  {platform.name}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" sx={{ color: '#666' }}>
                  Account Title
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium', color: '#E05B23' }}>
                  {platform.accountTitle}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body2" sx={{ color: '#666' }}>
                  Account Number
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium', color: '#E05B23' }}>
                  {platform.accountNumber}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PaymentDetails;
