// src/components/Gallery.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

// Import images
import floodImage from '../assets/floods.png';
import oldHomeImage from '../assets/oldHome.jpg';
import childrenImage from '../assets/childrenHome.jpg';
import iftaarImage from '../assets/iftar.png';
import toyImage from '../assets/toy_drive.jpg';
import pdpImage from '../assets/personal_dev.jpg';
import sosImage from '../assets/sos.png';
import police1 from '../assets/police1.PNG';
import police2 from '../assets/police2.PNG';
import police3 from '../assets/police3.PNG';
import police4 from '../assets/police4.PNG';
import police5 from '../assets/police5.PNG';
import street1 from '../assets/street1.PNG';
import street2 from '../assets/street2.PNG';
import street3 from '../assets/street3.PNG';
import street4 from '../assets/street4.jpg';
import widows1 from '../assets/widows.PNG';
// Import slugify utility function
import { createSlug } from '../utils/slugify';

// Define the specific cards to be shown in the gallery
const donationData = [
  {
    title: "Ehsaas: Support for Old-Age Homes",
    description: "Providing essential support and companionship to elders.",
    imageUrl: oldHomeImage,
  },
 
  {
    title: "Honoring Our Police Force",
    description: "Expressing gratitude to our protectors.",
    imageUrl: police2,
  },
  {
    title: "Flood Relief Efforts",
    description: "Emergency relief during floods.",
    imageUrl: floodImage,
  },
  {
    title: "Ramzan Razakar",
    description: "Helping others during Ramadan.",
    imageUrl: iftaarImage,
  },
  {
    title: "Street School Initiative",
    description: "Providing education to street children.",
    imageUrl: street4,
  },
  {
    title: "Wussat: Personal Growth and Development",
    description: "Fostering personal growth among youth.",
    imageUrl: pdpImage,
  },
  {
    title: "Toy Drives for Hospitalized Children",
    description: "Bringing joy to hospitalized kids with toys.",
    imageUrl: toyImage,
  }
];

const Gallery = () => {
  const [hoverIndex, setHoverIndex] = useState(null);

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  const sliderRef = React.useRef();

  const handleNext = () => sliderRef.current.slickNext();
  const handlePrev = () => sliderRef.current.slickPrev();

  return (
    <Box
      sx={{
        marginX: "auto",
        width: { xs: "100%", sm: "90%", md: "70%" },
        paddingY: 3,
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        component="h4"
        gutterBottom
        sx={{ mb: 4, mt: 2, fontWeight: "600" }}
      >
        Our Gallery
      </Typography>

      <Slider
        ref={sliderRef}
        {...settings}
        style={{ padding: "0 0px", position: "relative" }}
      >
        {donationData.map((item, index) => {
          const slug = createSlug(item.title);
          return (
            <Box
              key={index}
              sx={{
                padding: "0px 00px",
                margin: "15px",
                minWidth: 280,
                maxWidth: 280,
                display: "flex",
                borderRadius: 2,
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Link to={`/gallery/${slug}`} style={{ textDecoration: "none" }}>
                <Card
                  sx={{
                    position: "relative",
                    width: 280,
                    height: 350,
                    borderRadius: 2,
                    display: "flex",
                    overflow: "hidden",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={item.imageUrl}
                    alt={item.title}
                    sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                  <CardContent
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(0, 0, 0, 0.5)",
                      color: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      padding: 0,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", marginBottom: 1 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 2 }}>
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Box>
          );
        })}
      </Slider>

      <Box
        sx={{ display: "flex", justifyContent: "center", gap: 4, marginTop: 2 }}
      >
        <IconButton onClick={handlePrev} sx={{ color: "#ee5c24" }}>
          <ArrowBackIos />
        </IconButton>
        <IconButton onClick={handleNext} sx={{ color: "#ee5c24" }}>
          <ArrowForwardIos />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Gallery;
