import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import donationData from './donationData';

function AboutPage() {
  const { slug } = useParams(); 
  const donation = donationData.find(item => item.title.toLowerCase().replace(/\s+/g, '-') === slug);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrev = () => setCurrentImageIndex(prevIndex => (prevIndex === 0 ? donation.carouselImages.length - 1 : prevIndex - 1));
  const handleNext = () => setCurrentImageIndex(prevIndex => (prevIndex === donation.carouselImages.length - 1 ? 0 : prevIndex + 1));

  if (!donation) return <Typography variant="h4">Donation not found</Typography>;

  return (
    <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
      {/* Main Section with Background Image and Gradient */}
      <Box sx={{
          position: 'relative',
          width: '100%',
          height: '400px',
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: { xs: '5%', md: '24%' },
          paddingBottom: '110px',
          color: '#fff',
          backgroundImage: `linear-gradient(to right, rgba(255, 140, 0, 0.85), rgba(255, 140, 0, 0.1) 60%, transparent 80%), url(${donation.imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Darkens the image itself
            zIndex: 1,
          }
        }}>
        
        {/* Content displayed above the background and overlay */}
        <Box sx={{ 
          textAlign: { xs: 'center', md: 'left' },
          zIndex: 2, 
          maxWidth: '500px', 
          padding: { xs: '0 16px', md: '0' }
        }}>
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>{donation.title}</Typography>
          <Typography 
            variant="h6" 
            sx={{ 
              mt: 2, 
              textAlign: { xs: 'center', md: 'left' } 
            }}
          >
            {donation.backgroundText}
          </Typography>
        </Box>
      </Box>

      {/* White Box with Description, positioned separately */}
      <Box sx={{ 
          margin: '20px auto', 
          backgroundColor: '#fff', 
          padding: '1.5rem 2rem', 
          width: '70%', 
          maxWidth: '800px', 
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
          textAlign: 'center', 
          borderRadius: 2,
          position: 'relative',
          zIndex: 3,
          top: '-50px'
        }}>
        <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.6, color: '#333' }}>{donation.whiteBoxText}</Typography>
      </Box>

      {/* Additional Section Below White Box */}
      <Box sx={{ margin: '2rem auto', padding: '2rem', backgroundColor: '#f4f6f8', width: '90%', maxWidth: '1200px', borderRadius: '8px', textAlign: 'left', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>{donation.additionalSectionTitle}</Typography>
        <Typography variant="body1" sx={{ fontSize: '1rem', lineHeight: 1.6, color: '#333' }}>{donation.additionalSectionText}</Typography>
      </Box>

      {/* Carousel Section */}
      <Box sx={{ position: 'relative', margin: '4rem auto', width: '80%', maxWidth: '1000px', textAlign: 'center' }}>
        {/* Main Image Display */}
        <Box sx={{ position: 'relative', height: '500px', backgroundColor: '#f4f6f8', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', overflow: 'hidden' }}>
          <img src={donation.carouselImages[currentImageIndex]} alt={`${donation.title} - ${currentImageIndex}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>

        {/* Navigation Arrows */}
        <IconButton onClick={handlePrev} sx={{
            position: 'absolute',
            left: '-50px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#333',
            backgroundColor: '#fff',
            borderRadius: '50%',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
            ':hover': { backgroundColor: '#eee' },
            width: '40px',
            height: '40px',
          }}><ArrowBackIos /></IconButton>
        <IconButton onClick={handleNext} sx={{
            position: 'absolute',
            right: '-50px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#333',
            backgroundColor: '#fff',
            borderRadius: '50%',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
            ':hover': { backgroundColor: '#eee' },
            width: '40px',
            height: '40px',
          }}><ArrowForwardIos /></IconButton>

        {/* Thumbnails Below Carousel */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, gap: 1 }}>
          {donation.carouselImages.map((image, index) => (
            <Box key={index} onClick={() => setCurrentImageIndex(index)} sx={{
                width: '80px',
                height: '60px',
                cursor: 'pointer',
                opacity: currentImageIndex === index ? 1 : 0.6,
                border: currentImageIndex === index ? '2px solid #333' : 'none',
                ':hover': { opacity: 1 },
              }}>
              <img src={image} alt={`${donation.title} - thumbnail ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px' }} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default AboutPage;
