import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, CardContent } from '@mui/material';
import { ReactTyped as Typed } from 'react-typed';
import imageUrl from '../assets/founder.jpeg'; // Replace with your image path
import colors from '../styles/Colors'; // Import your color palette

function FounderCard() {
  const [isVisible, setIsVisible] = useState(false);
  const aboutCardRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once it becomes visible
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is in view
    );

    if (aboutCardRef.current) {
      observer.observe(aboutCardRef.current);
    }

    return () => {
      if (aboutCardRef.current) {
        observer.unobserve(aboutCardRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={aboutCardRef}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '100%',
        minHeight: '400px',
        padding: { xs: '2rem', md: '4rem' },
        position: 'relative',
        overflow: 'hidden',
        boxSizing: 'border-box',
        backgroundColor: colors.darkYellow,
        margin: '0 auto',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.5, // Adjusted for better image visibility
          zIndex: 0,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent black overlay
          zIndex: 1,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          width: '100%',
          maxWidth: '1200px', // Restrict max width for large screens
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
          zIndex: 2, // Ensure content appears above the overlay
          gap: { xs: 2, md: 4 },
        }}
      >
        {/* Left side image */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            border: '5px solid white',
            boxSizing: 'border-box',
            width: { xs: '100%', md: '50%' },
            minHeight: { xs: '300px', md: '400px' },
            height: '100%',
            borderRadius: 2,
            margin: '0 auto',
          }}
        />
        
        {/* Right side content with typing effect */}
        <CardContent
          sx={{
            flex: 1,
            color: '#fff',
            padding: { xs: 2, md: 4 },
            textAlign: { xs: 'center', md: 'left' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 1 }}>
            Our Founder
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, lineHeight: 1.6 }}>
            {isVisible && (
              <Typed
                strings={[
"Talha Azhar founded Ideas Foundation with a simple yet powerful mission: to bring a sense of family to orphaned children who often grow up without that connection. Inspired by his experiences helping earthquake victims in 2005, Talha realized that while many children have access to basic needs, they lack the emotional support and guidance a family provides. Since establishing Ideas Foundation in 2010, Talha has built a community that encourages volunteers to become mentors, friends, and even siblings to these children. His bond with places like SOS Children’s Village runs deep, where he is lovingly embraced by the children. Through Ideas Foundation, Talha continues to impact lives, believing that small gestures of kindness can create lasting change."                ]}
                typeSpeed={40} // Adjust speed as needed
              />
            )}
          </Typography>
        </CardContent>
      </Box>
    </Box>
  );
}

export default FounderCard;
