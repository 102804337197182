// src/data/donationData.js
import floodImage from '../assets/floods.png';
import oldHomeImage from '../assets/oldHome.jpg';
import iftaarImage from '../assets/iftar.png';
import iftaarImage1 from '../assets/iftar4.PNG';
import iftaarImage2 from '../assets/iftar9.jpg';
import iftaarImage3 from '../assets/iftae7.PNG';
import oldage1 from '../assets/oldage1.jpg';
import oldage2 from '../assets/oldage2.PNG';
import police2 from '../assets/police2.PNG';
import police3 from '../assets/police3.PNG';
import police4 from '../assets/police4.PNG';
import police5 from '../assets/police5.PNG';
import floodimage1 from '../assets/flood1.PNG';
import floodimage7 from '../assets/relief6.PNG';
import floodimage8 from '../assets/relief7.PNG';
import street1 from '../assets/street1.PNG';
import street2 from '../assets/street2.PNG';
import street3 from '../assets/street3.PNG';
import street4 from '../assets/street4.jpg';
import pdev from '../assets/personal_dev.jpg';
import pdev1 from '../assets/pers1.PNG';
import pdev2 from '../assets/pers2.PNG';
import pdev3 from '../assets/pers3.PNG';
import toyImage2 from '../assets/toy3.PNG';
import toyImage8 from '../assets/toy9.PNG';
import toyImage11 from '../assets/toy12.PNG';
import toyImage12 from '../assets/relief8.PNG';


const donationData = [
    {
        title: "Ehsaas: Support for Old-Age Homes",
        backgroundText: "Bringing warmth, dignity, and companionship to our elderly community.",
        whiteBoxText: "Through Ehsaasi, we extend support to elderly residents in old-age homes, providing essential supplies, compassionate companionship, and heartfelt care.",
        additionalSectionTitle: "Ehsaasi: Caring for Our Elderly",
        additionalSectionText: "In our society, the elderly are a precious source of wisdom and experience. Through the Ehsaasi program, our dedicated team visits old-age homes, offering all-around support to ensure the residents feel valued and cherished. We bring necessary supplies, share meaningful conversations, and offer companionship to brighten their days. This initiative goes beyond material assistance; it's about fostering a sense of belonging, showing our elderly that they are not forgotten, and ensuring they live with dignity and love. By bridging generations, Ehsaasi aims to create a compassionate community where every elder feels respected and cared for.",
        imageUrl: oldage1,
        carouselImages: [oldHomeImage, oldage1, oldage2],
    },
    // {
    //     title: "Empowering Widows",
    //     backgroundText: "Offering hope, support, and pathways to independence for widows.",
    //     whiteBoxText: "Empowering widows to reclaim their independence and build brighter futures through tailored support and training programs.",
    //     additionalSectionTitle: "Widow Empowerment Program",
    //     additionalSectionText: "Our Widow Empowerment program is dedicated to uplifting widows by providing essential resources, skill-building opportunities, and a strong community network. Through targeted support and vocational training, we help widows gain financial independence, rebuild their confidence, and reintegrate into society with dignity. This initiative ensures that every widow receives not just material support, but also emotional encouragement, community connections, and the tools needed to create a sustainable future. Together, we work to transform lives, foster resilience, and inspire hope.",
    //     imageUrl: widows1,
    //     carouselImages: [widows1],
    // },
    {
        title: "Honoring Our Police Force",
        backgroundText: "Honoring and supporting those who protect and serve.",
        whiteBoxText: "Expressing our deep gratitude to the police force by recognizing their sacrifices and offering meaningful support to them and their families.",
        additionalSectionTitle: "Police Appreciation and Support Program",
        additionalSectionText: "Our Police Support Program is dedicated to showing appreciation for the courage and dedication of our police force. Through community-driven initiatives, we provide care packages, organize recognition events, and offer resources to support their families. Our volunteers actively work to convey our gratitude by standing with these brave individuals who put their lives on the line every day. This program ensures that they feel valued and supported, reminding them that their commitment to public safety does not go unnoticed. Together, we strive to build a community that respects, appreciates, and uplifts its protectors.",
        imageUrl: police5,
        carouselImages: [police2, police3, police4],
    },
    {
        title: "Flood Relief Efforts",
        backgroundText: "Swift and compassionate relief during devastating floods.",
        whiteBoxText: "Our dedicated relief team provides urgent support, resources, and hope to families impacted by floods.",
        additionalSectionTitle: "Emergency Flood Relief and Support",
        additionalSectionText: "In the wake of catastrophic floods, our Flood Relief Program mobilizes quickly to offer immediate aid to affected communities. From providing essential supplies, such as food, clean water, and medical care, to setting up temporary shelters, our team is committed to supporting those in urgent need. We work closely with local authorities and volunteers to assess needs on the ground and bring comfort to families during these challenging times. Our efforts go beyond immediate relief, as we also aim to assist in the long-term recovery process, helping families and communities rebuild with resilience and hope.",
        imageUrl: floodimage7,
        carouselImages: [floodimage8,floodimage1 , floodImage ],
    },
    {
        title: "Ramzan Razakar",
        backgroundText: "Spreading kindness and compassion during the sacred month of Ramadan.",
        whiteBoxText: "Our Ramzan Razakar initiative provides free iftar meals to underprivileged families, ensuring they can break their fast with nourishment and dignity.",
        additionalSectionTitle: "The Ramzan Razakar Program",
        additionalSectionText: "The holy month of Ramadan is a time for reflection, empathy, and generosity. Through our Ramzan Razakar program, we provide iftar meals to families who may not have the means to secure adequate food. Volunteers come together to prepare, package, and distribute meals, allowing families to break their fast without worry. This initiative aims to ensure that no one has to endure hunger during this sacred time, and that every family can join in the blessings and spirit of Ramadan. Together, we bring warmth, care, and sustenance to those who need it most, fostering a community of compassion and support.",
        imageUrl: iftaarImage,
        carouselImages: [iftaarImage1, iftaarImage2, iftaarImage3],
    },
    {
        title: "Street School Initiative",
        backgroundText: "Empowering street children through education and opportunity.",
        whiteBoxText: "Our Street School Initiative opens doors to education for children living on the streets, giving them a path toward a brighter future.",
        additionalSectionTitle: "Street School Initiative",
        additionalSectionText: "Through our Street School Initiative, we provide street children with access to education, helping them develop basic literacy, numeracy, and life skills. Our program not only aims to teach but also to inspire, giving these children a safe learning environment, mentorship, and the encouragement to dream bigger. By addressing their unique challenges, we strive to break the cycle of poverty and open up possibilities for a hopeful future. Our dedicated volunteers and educators work tirelessly to ensure these children feel supported, valued, and motivated to reach their full potential.",
        imageUrl: street4,
        carouselImages: [street1, street2, street3],
    },
    {
        title: "Wussat: Personal Growth and Development",
        backgroundText: "Empowering youth to realize their potential through growth and development.",
        whiteBoxText: "Wussat equips young individuals with essential life skills, preparing them for personal and professional success.",
        additionalSectionTitle: "Youth Empowerment & Personal Growth Program",
        additionalSectionText: "The Wussat initiative focuses on nurturing the next generation by providing youth with access to workshops, mentorship, and skill-building activities. Our program covers a range of essential life skills—from communication and leadership to emotional intelligence and self-discipline. With guidance from mentors and exposure to real-world challenges, participants are empowered to overcome obstacles, build resilience, and pursue their dreams with confidence. Wussat fosters a supportive environment where young people can explore their passions, set meaningful goals, and develop a strong foundation for a successful future.",
        
        imageUrl: pdev,
        carouselImages: [pdev1, pdev2,pdev3],
    },
    {
        title: "Toy Drives for Hospitalized Children",
        backgroundText: "Bringing happiness and hope to hospitalized children through thoughtful gifts.",
        whiteBoxText: "Our Toy Drive initiative spreads joy and comfort to children in hospitals by providing them with toys and personalized care packages.",
        additionalSectionTitle: "Toy Drive & Support Program",
        additionalSectionText: "Through our Toy Drive, we aim to uplift the spirits of children facing medical challenges by delivering carefully selected toys and care packages directly to them. Each gift is chosen to bring joy, comfort, and a sense of normalcy during a difficult time, helping to make hospital stays a little brighter. Volunteers personally distribute toys, interact with the children, and create a supportive environment to bring smiles and moments of happiness. This program underscores our commitment to caring for young patients by providing them with cherished moments of relief and companionship when they need it most.",
        
        imageUrl: toyImage2,
        carouselImages: [toyImage12,toyImage8 , toyImage11 ],
    },
];

export default donationData;
