import React, {useRef, useState, useEffect } from 'react';
import { Box, Typography, CardContent } from '@mui/material';
import { ReactTyped as Typed } from 'react-typed';
import newImage from '../assets/waw1.jpeg';
import colors from '../styles/Colors';

function AboutCard() {
  const [isVisible, setIsVisible] = useState(false);
  const aboutCardRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 }
    );

    if (aboutCardRef.current) {
      observer.observe(aboutCardRef.current);
    }

    return () => {
      if (aboutCardRef.current) {
        observer.unobserve(aboutCardRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={aboutCardRef}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '100%',
        minHeight: '400px',
        padding: { xs: '2rem', md: '4rem' },
        position: 'relative',
        overflow: 'hidden',
        backgroundRepeat: 'no-repeat',

        boxSizing: 'border-box',
        background: 'linear-gradient(135deg, #DFFFA6, #F6E79C)',
        margin: '0 auto',
        '&::before': {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${newImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          opacity: 0.2,
          zIndex: 0,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.15)',
          zIndex: 1,
          backgroundRepeat: 'no-repeat',

        }
        ,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
          zIndex: 2,
        }}
      >
        {/* Left side content with typing effect */}
        <CardContent
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            color: '#333',
            padding: { xs: 2, md: 4 },
            textAlign: { xs: 'center', md: 'left' },
            maxWidth: { xs: '100%', md: '50%' },
          }}
        >
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '2rem', color: '#274D00', mt: 1 }}>
              Who are we?
            </Typography>
            <Typography variant="body1" sx={{ mt: 2, lineHeight: 1.8, fontSize: '1rem' }}>
              <Typed
                strings={["Ideas foundation was established in 2011. We, at Ideas, envision a world where all people especially youth hold the power to create opportunity for themselves and others. Our vision is to leave a sustainable world for future generations. It is a nonprofit organization run by the youth and for the youth. The benchmark of this organization is its volunteers, the volunteers who have never let down the trust of this organization. We aim to provide the youth with a platform where they can practically apply their altruistic ideas for the well-being of others, serve the community, and gain insightful experiences."]}
                typeSpeed={35}
              />
            </Typography>
          </Box>
        </CardContent>

        {/* Right side image with border and rounded corners */}
        <Box
          sx={{
            flex: 1.5,
            backgroundImage: `url(${newImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            width: { xs: '100%', md: '60%' },
            minHeight: { xs: '350px', md: '525px' },
            backgroundRepeat: 'no-repeat',

            height: '70%',
            padding: '5px', // Space between the image and border
            border: '2px solid white', // White border
            borderRadius: '12px', // Rounded corners for the image border
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
            margin: { xs: '2rem 0', md: '0' },
          }}
        />
      </Box>
    </Box>
  );
}

export default AboutCard;
