import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import heroImage3 from '../assets/empower.jpg';
import heroImage from '../assets/sfaw2020.png';
import heroImageLow from '../assets/sfaw2020_low_res.jpg';
import heroImage2 from '../assets/ideas_cover-min.jpg';
import heroImage2Low from '../assets/ideas_cover-min_low_res.jpg';
import heroImage3Low from '../assets/empower_low_res.jpg';
import '../App.css';

const heroData = [
  {
    image: heroImage,
    lowResImage: heroImageLow,
    title: "BE THE BLESSING FOR EVERYONE",
    subtitle: "Join us in our mission to bring hope and change.",
  },
  {
    image: heroImage2,
    lowResImage: heroImage2Low,
    title: "INSPIRE POSITIVE CHANGE",
    subtitle: "Together, we can make a difference in society.",
  },
  {
    image: heroImage3,
    lowResImage: heroImage3Low,
    title: "EMPOWER THE FUTURE",
    subtitle: "Help us provide opportunities and build brighter futures.",
  },
];

function Hero() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHighResLoaded, setIsHighResLoaded] = useState(false);
  const [fade, setFade] = useState(true);
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % heroData.length);
        setIsHighResLoaded(false);
        setFade(true);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Ensure the font is loaded before applying it
    document.fonts.load('1em Cooper Black').then(() => {
      setIsFontLoaded(true);
    });
  }, []);

  const { image, lowResImage, title, subtitle } = heroData[currentIndex];

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => setIsHighResLoaded(true);
  }, [image]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        zIndex: -1,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${lowResImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(10px)',
          opacity: 1,
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transition: 'opacity 0.5s ease-in-out',
          opacity: isHighResLoaded && fade ? 1 : 0,
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 2,
        }}
      />
      <Box
        sx={{
          position: 'relative',
          zIndex: 3,
          textAlign: 'center',
          color: 'white',
          opacity: fade ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: isFontLoaded ? 'Cooper Black' : 'Arial', // Use fallback font while loading
            fontSize: { xs: '2rem', md: '3.5rem' },
            fontWeight: 'bold',
            lineHeight: { xs: 1.2, md: 1.5 },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginTop: 2,
            fontSize: { xs: '1rem', md: '1.25rem' },
            maxWidth: { xs: '90%', md: 600 },
            mx: 'auto',
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}

export default Hero;
