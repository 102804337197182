// Import axios from the library
import axios from "axios";
import { BASE_URL } from "./constant";

// Function to set the navigate function
let navigateCallback;

// export function setNavigateCallback(callback) {
//   navigateCallback = callback;
// }

// Create a custom axios instance with default headers and base URL
const API = axios.create({
  // Set the base URL to the server API
  baseURL: process.env.REACT_APP_BASE_URL,
  // Set the default headers for every request
  headers: {
    // Set the content type header to JSON
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to modify the default configuration
API.interceptors.request.use((config) => {
  // Get the access token from local storage
  const accessToken = window.localStorage.getItem("access-token");
  // Merge the default headers with the custom authorization header if available
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

//Function to regenerate access token from refresh token
const refreshAccessToken = async () => {
  try {
    const refreshToken = window.localStorage.getItem("refresh-token");
    if (refreshToken) {
      const response = await axios.post(
        `http://65.2.122.58:3006/api/auth/refresh`,
        {},
        { headers: { Authorization: `Bearer ${refreshToken}` } }
      );
      // Retrieve the new access token from the response
      const newAccessToken = response.data.tokens.access_token;
      const newRefreshToken = response.data.tokens.refresh_token;
      // Update the stored access token
      window.localStorage.setItem("access-token", newAccessToken);
      window.localStorage.setItem("refresh-token", newRefreshToken);
      return newAccessToken;
    }
  } catch (error) {
    //If 401 error occurs then redirect to login page
    if (error.response.status === 401) {
      localStorage.clear();
      navigateCallback();
    }
  }
};

// API.interceptors.response.use(
//   (res) => res,
//   async (err) => {
//     if (err.response.data.statusCode === 401) {
//       try {
//         const newAccessToken = await refreshAccessToken();
//         if (newAccessToken) {
//           // Retry the original request with the new access token
//           const originalRequest = err.config;
//           originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
//           return axios(originalRequest);
//         }
//       } catch (refreshError) {
//         console.error("Token refresh failed:", refreshError);
//       }
//     } else {
//       console.log("eeeeeeeeee");
//       throw new Error(err.response.data.message);
//     }
//   }
// );
// Export the custom axios instance
export default API;
