import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Navbar from './components/Navbar'; // Navbar component
import PaymentDetails from './components/DonationCard'; // PaymentDetails component
import DonationDetails from './components/DonationCardDiv'; // DonationDetails component
import Hero from './components/Hero';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import AboutPage from './components/AboutPage';
import FounderCard from './components/FounderCard';
import AboutCard from './components/AboutCard';
import MissionComponent from './components/Mission';
import ScrollToTop from './components/ScrollToTop'; // Ensures the page opens from top
import UpcomingEvents from './components/UpcomingEvents';

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false); // state to control the popup visibility

  const handlePopupToggle = () => {
    setIsPopupOpen(!isPopupOpen); // toggle the popup visibility
  };

  const closePopup = (e) => {
    // Close the popup if clicked outside the popup area
    if (e.target.id === 'popup-overlay') {
      setIsPopupOpen(false);
    }
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar onDonateClick={handlePopupToggle} /> {/* Pass the function as prop */}
      
      <Routes>
        <Route
          path="/"
          element={
            <Box sx={{ pt: '100vh' }}>
              <section id="Home">
                <Hero />
              </section>
              <section id="Mission">
                <Box sx={{ minHeight: '10vh', backgroundColor: '#fff' }}>
                  <MissionComponent />
                </Box>
              </section>
              <section id="Events">
                <UpcomingEvents />
              </section>
              <section id="About">
                <Box sx={{ minHeight: '10vh', backgroundColor: '#fff' }}>
                  <AboutCard />
                </Box>
              </section>
              <section id="Gallery">
                <Box sx={{ minHeight: '10vh', backgroundColor: '#f5f5f5' }}>
                  <Gallery />
                </Box>
              </section>
              {/* <section id="Founder">
                <Box sx={{ minHeight: '10vh', backgroundColor: '#fff' }}>
                  <FounderCard />
                </Box>
              </section> */}
            </Box>
          }
        />
        <Route path="/gallery/:slug" element={<AboutPage />} />
        <Route path="/founder" element={<FounderCard />} />

      </Routes>

      {/* Show the popup when the state is true */}
      {isPopupOpen && (
        <div
          id="popup-overlay"
          onClick={closePopup} // Close popup if clicked outside
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 11, 

          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              maxWidth: '700px',  // Limit the width to prevent it from becoming too wide
              width: '90%',  // Ensure it takes 90% of the screen width for responsiveness
              maxHeight: '80vh',  // Limit the height to avoid it becoming too large
              overflowY: 'auto',  // Enable scrolling if content is taller than max height
              boxSizing: 'border-box',
              zIndex: 5, 
            }}
          >
            <PaymentDetails /> {/* Display the payment details */}
            <button
              onClick={handlePopupToggle} // Close the popup
              style={{
                marginTop: '10px',
                padding: '10px',
                backgroundColor: '#E05B23',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <section id="Contact">
      <Footer />

              </section>
    </BrowserRouter>
  );
}


export default App;
