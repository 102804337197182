import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import colors from '../styles/Colors';
import { Box, Typography, CardContent } from '@mui/material';

const Footer = () => {
  const navigate = useNavigate();

  // Function to handle navigation and scrolling
  const handleNavigation = (label) => {
    if (label === "Home") {
      navigate("/"); // Navigate to home page
    } else if (label === "About") {
      navigate("/"); // Navigate to home page
      setTimeout(() => {
        const aboutSection = document.getElementById("About");
        if (aboutSection) {
          aboutSection.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to About section
        }
      }, 100); // Adding delay to allow page load before scrolling
    }
  };

  const styles = {
    footer: {
      backgroundColor: '#2f2f2f',
      color: '#f1f1f1',
      padding: '40px 20px',
      textAlign: 'center',
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
    },
    socialSection: {
      marginBottom: '20px',
      textAlign: 'center',
    },
    socialIcons: {
      display: 'flex',
      justifyContent: 'center',
      gap: '15px',
      marginTop: '15px',
    },
    socialIcon: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f1f1f1',
      color: '#2f2f2f',
      fontSize: '20px',
      transition: 'transform 0.3s ease, background-color 0.3s ease',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.1)',
        backgroundColor: '#FF8B04',
        color: '#fff',
      },
    },
    footerLinks: {
      display: 'flex',
      justifyContent: 'center',
      gap: '25px',
      fontSize: '0.9em',
      color: '#f1f1f1',
      margin: '20px 0',
      flexWrap: 'wrap',
    },
    link: {
      color: 'inherit',
      textDecoration: 'underline', // Underline the text links
      transition: 'color 0.3s ease',
      fontWeight: '200',
      cursor: 'pointer', // Ensure the links are clickable
      '&:hover': {
        color: '#FF8B04',
      },
    },
    footerBottom: {
      marginTop: '30px',
      fontSize: '0.9em',
      color: '#aaa',
    },
  };

  return (
    <footer style={styles.footer}>
      <div style={styles.container}>

        {/* Social Media Section */}
        <div style={styles.socialSection}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FF8B04' }}>
            Connect with Us
          </Typography>
          <Typography variant="body2" sx={{ color: '#f1f1f1', marginTop: '10px' }}>
            Follow us on social media for the latest updates and opportunities to get involved!
          </Typography>
          <div style={styles.socialIcons}>
            <a href="https://www.facebook.com/ideasfoundationpak/" target="_blank" rel="noopener noreferrer" style={styles.socialIcon}>
              <FaFacebook />
            </a>
            <a href="https://www.instagram.com/ideas_foundation/?hl=en" target="_blank" rel="noopener noreferrer" style={styles.socialIcon}>
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com/company/ideas-foundation-pakistan" target="_blank" rel="noopener noreferrer" style={styles.socialIcon}>
              <FaLinkedin />
            </a>
          </div>
        </div>

        {/* Footer Links */}
        <div style={styles.footerLinks}>
          <a
            style={styles.link}
            onClick={() => handleNavigation("Home")}
          >
            Home
          </a>
          <a
            style={styles.link}
            onClick={() => handleNavigation("About")}
          >
            About
          </a>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfdv4bPiscm-fpzWBPRCEzcgOc2tO8bvRZm7rvifrY7YBg05w/viewform" style={styles.link} target="_blank" rel="noopener noreferrer">
            Become a Volunteer
          </a>
          <a href="/founder" style={styles.link}>
            Our Founder
          </a>
          <a href="/admin" style={styles.link}>
            Admin
          </a>
        </div>

        {/* Footer Bottom */}
        <div style={styles.footerBottom}>
          <p>© 2024 Ideas Foundation. All rights reserved. Powered by <strong>
            <a href="https://thecallistosolutions.com" style={styles.link} target="_blank" rel="noopener noreferrer">
              Callisto Solutions
            </a></strong></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
