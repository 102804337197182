import React, { useRef, useState, useEffect } from "react";
import { Box, Typography, CardContent, IconButton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { fetchAllEvents } from "../hooks/event";
import colors from "../styles/Colors";
import LocationOnIcon from "@mui/icons-material/LocationOn"; // Importing pin icon

function capitalizeWords(str) {
  return str
    .split(" ") // Split string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a string
}

function formatDate(dateString) {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = new Date(dateString);

  // Format the date part
  const formattedDate = date.toLocaleDateString("en-US", options);

  // Format the time part
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return `${formattedDate}, ${formattedTime}`;
}

function UpcomingEvents() {
  const swiperRef = useRef(null);
  const [sliderData, setSliderData] = useState([]);
  const [error, setError] = useState(false); // Track error or no data

  // Static dummy data to be used in case of error
  const dummyData = [
    {
      _id: "67347861a22e17ba3ac17e8c",
      title: "clay day",
      location: "SOS Village , H11/4, Islamabad",
      date: "2024-12-15T09:00:00.000Z",
      poster:
        "https://res.cloudinary.com/dp4h34avd/image/upload/v1731491937/tpl7hcxl3onhpfvs1slz.jpg",
      description:
        "A day filled with fun activities, art, and crafts. Unleash your creativity and join us for hands-on projects. This is the perfect opportunity to express yourself through art!",
    },
    {
      _id: "673478a0a22e17ba3ac17e91",
      title: "color day",
      location: "SOS Village, H-11/4, Islamabad",
      date: "2024-12-16T09:00:00.000Z",
      poster:
        "https://res.cloudinary.com/dp4h34avd/image/upload/v1731491999/jbyvsx4tt0mh8c6jei7d.jpg",
      description:
        "A celebration of colors with games and interactive sessions. Bring your friends and family for an unforgettable day of fun. Together, we'll paint the world with vibrant hues!",
    },
    {
      _id: "67347fe9a22e17ba3ac17e9b",
      title: "youth club day",
      location: "SOS Village, H-11/4, Islamabad",
      date: "2024-12-17T09:00:00.000Z",
      poster:
        "https://res.cloudinary.com/dp4h34avd/image/upload/v1732031372/ogjyigqmln2vaqkvavud.jpg",
      description:
        "An event focusing on youth empowerment and leadership. Be part of exciting workshops and activities that will shape your future. It's your time to shine and make a difference!",
    },
    {
      _id: "67348040a22e17ba3ac17ea5",
      title: "naiki day",
      location: "SOS Village, H-11/4, Islamabad",
      date: "2024-12-18T09:00:00.000Z",
      poster:
        "https://res.cloudinary.com/dp4h34avd/image/upload/v1731493952/yvxhtbot0n6rtbqyhnvd.jpg",
      description:
        "A day dedicated to community service and giving back. Join us to make a lasting impact on the lives of others. Your small acts of kindness will help brighten someone's day!",
    },
    {
      _id: "67348066a22e17ba3ac17eaa",
      title: "game day",
      location: "SOS Village, H-11/4, Islamabad",
      date: "2024-12-19T09:00:00.000Z",
      poster:
        "https://res.cloudinary.com/dp4h34avd/image/upload/v1731493990/twy2ctjuwtreaioyjj9u.jpg",
      description:
        "A fun-filled day with various competitive games. Challenge your friends and test your skills in a friendly competition. The thrill of the game awaits you!",
    },
    {
      _id: "67348180a22e17ba3ac17eb0",
      title: "urdu adab day",
      location: "SOS Village, H-11/4, Islamabad",
      date: "2024-12-20T09:00:00.000Z",
      poster:
        "https://res.cloudinary.com/dp4h34avd/image/upload/v1731494271/io47zxfw4vloirnchzk7.jpg",
      description:
        "A cultural day celebrating Urdu literature and traditions. Dive into the beauty of poetry, stories, and art. Let's honor the rich heritage of Urdu with love and passion!",
    },
    {
      _id: "673481b9a22e17ba3ac17eb6",
      title: "theatre day",
      location: "SOS Village, H-11/4, Islamabad",
      date: "2024-12-21T09:00:00.000Z",
      poster:
        "https://res.cloudinary.com/dp4h34avd/image/upload/v1731494329/epvedonw2pyl3fbeybu0.jpg",
      description:
        "An event dedicated to theatre arts and performances. Watch talented performers bring stories to life on stage. Feel the drama and excitement in every act, and become part of the artistic community!",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAllEvents();
        if (response.data.length > 0) {
          setSliderData(response.data); // Populate the slider data if events exist
          setError(false); // Clear any error if data is fetched successfully
        } else {
          setSliderData(dummyData); // Use dummy data if no events are returned
          setError(true); // Set error to display "No upcoming events"
        }
      } catch (error) {
        console.error("Error fetching events:", error);
        setSliderData(dummyData); // Use dummy data in case of error
        setError(true); // Set error to display "No upcoming events"
      }
    };

    fetchData();
  }, []);
  const handleLocationClick = (location) => {
    // Open the location in Google Maps
    const mapUrl = `https://maps.app.goo.gl/oyowoKrBvHwSNg857`;
    window.open(mapUrl, "_blank");
  };
  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        background: "rgba(242, 242, 242)",
        padding: "2rem 0",
        textAlign: "center",
        position: "relative",
      }}
    >
      {/* Title */}
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          mb: 3,
          fontFamily: "Cooper Black",
          color: colors.darkOrange,
        }}
      >
        Upcoming Events
      </Typography>

      <Box
        sx={{
          maxWidth: "90%",
          margin: "0 auto",
          padding: "1rem 0",
          position: "relative",
        }}
      >
        {/* Show "No upcoming events" message if error or no data */}

        {/* error ? (
          <Typography variant="h6" color="textSecondary">
            No upcoming events right now. Stay tuned.
          </Typography>
        ) : */}

        {/* Swiper Slider */}
        {sliderData.length > 0 && (
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            spaceBetween={20}
            loop={true}
            modules={[Navigation]}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            style={{ width: "100%" }}
          >
            {sliderData.map((slide) => (
              <SwiperSlide key={slide._id}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "1.5rem",
                    borderRadius: 2,
                    overflow: "hidden",
                    flexDirection: { xs: "column", md: "row" },
                    minHeight: "350px",
                    backgroundImage: `linear-gradient(rgba(255, 165, 0, 0.8), rgba(255, 255, 225, 0.8)), url(${slide.poster})`, // Set color overlay opacity to 0.4, image has reduced opacity
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {/* Left Side Text Content */}
                  <CardContent
                    sx={{
                      width: { xs: "100%", md: "50%" },
                      padding: 3,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      textAlign: "left",
                      color: "black",
                      ml: { md: 3 },
                      mb: { xs: 2, md: 0 },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        mb: 4,
                        fontFamily: "Cooper Black",
                      }}
                    >
                      {capitalizeWords(slide.title)}
                    </Typography>
                    <Typography variant="subtitle0" sx={{ mb: 1 }}>
                      {slide.description}
                    </Typography>
                    {/* <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      Location:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {slide.location}
                      </span>
                    </Typography> */}
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      Location:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {slide.location}
                      </span>
                      <IconButton
                        onClick={() => handleLocationClick(slide.location)}
                        sx={{
                          color: "black",
                          fontSize: "1.5rem",
                        }}
                      >
                        <LocationOnIcon />
                      </IconButton>
                    </Typography>
                    {/* Date & Time with bold label */}
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      Date & Time:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {formatDate(slide.date)}
                      </span>
                    </Typography>
                  </CardContent>

                  {/* Right Side Image Content */}
                  <Box
                    sx={{
                      width: { xs: "100%", md: "60%" },
                      height: "100%",
                      minHeight: "350px",
                      backgroundImage: `url(${slide.poster})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: 2,
                      mr: { md: 5 },
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {/* Custom Navigation Buttons */}
        <IconButton
          className="swiper-button-prev"
          sx={{
            position: "absolute",
            left: "-50px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "black",
            fontSize: "2.5rem",
            display: { xs: "none", md: "flex" },
            "&:hover": { color: "gray" },
          }}
        >
          {/* <ArrowBackIosIcon fontSize="inherit" /> */}
        </IconButton>

        <IconButton
          className="swiper-button-next"
          sx={{
            position: "absolute",
            right: "-50px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "black",
            fontSize: "2.5rem",
            display: { xs: "none", md: "flex" },
            "&:hover": { color: "gray" },
          }}
        >
          {/* <ArrowForwardIosIcon fontSize="inherit" /> */}
        </IconButton>

        {/* Arrows Under the Card for Small Screens */}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "center",
            mt: 2,
            gap: 2,
          }}
        >
          <IconButton
            className="swiper-button-prev"
            sx={{
              color: "black",
              fontSize: "2.5rem",
              mr: 2,
              "&:hover": { color: "gray" },
            }}
          >
            {/* <ArrowBackIosIcon fontSize="inherit" /> */}
          </IconButton>

          <IconButton
            className="swiper-button-next"
            sx={{
              color: "black",
              fontSize: "2.5rem",
              "&:hover": { color: "gray" },
            }}
          >
            {/* <ArrowForwardIosIcon fontSize="inherit" /> */}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default UpcomingEvents;
